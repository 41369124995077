<template>
  <div id="tab-server" class="tab-pane">
    <div class="panel-body-api">
      <div v-if="!openapi.servers || openapi.servers.length == 0">
        <div class="card-content">
          <p class="title">
            <a v-bind:href="specLink('#serverObject')" target="_blank"
              >OpenAPI Specification Servers Object</a
            >
          </p>
          <div class="hr-line-dashed"></div>
          <span>
            There are no servers defined. This means the API paths will be
            relative to the host and path the OpenAPI definition was loaded
            from.
          </span>
        </div>
        <br />
        <button
          v-on:click="addServer()"
          type="button"
          class="btn btn-w-m btn-primary"
        >
          <i class="fa fa-fw fa-plus"></i> Create Server
        </button>
      </div>
      <div v-for="(server, index) in openapi.servers" v-bind:key="index">
      <div class="card-content">
        <div class="row">
          <a
            class="btn btn-white btn-bitbucket server-content"
            v-on:click="addServer()"
          >
            <i class="fa fa-plus"></i>
          </a>
          <a
            class="btn btn-white btn-bitbucket server-content"
            v-on:click="removeServer(index)"
          >
            <i class="fa fa-trash"></i>
          </a>

          <input
            type="text"
            placeholder="URL"
            v-model="server.url"
            class="form-control col-sm-4 server-content"
          />
          <input
            type="text"
            placeholder="Description"
            v-model="server.description"
            class="form-control col-sm-4 server-content"
          />

          <a
            class="btn btn-white btn-bitbucket server-content"
            v-on:click="addVariable(index)"
          >
            <i class="fa fa-plus"></i> Add Variable
          </a>

          <api-srvvar
            v-for="(variable, index) in server.variables"
            :name="index"
            :key="index"
            :variable="variable"
            :server="server"
            inline-template
          >
            <div>
              <div class="ibox-content" style="border-style: none none none;">
                <i class="fa fa-trash" style="margin-left: 223%;" v-on:click="removeVariable()"></i>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Name</label>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control variable-text"
                      v-model.lazy="variableName"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Description</label>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control input-group variable-text"
                      v-model="variable.description"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Default</label>

                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control variable-text"
                      v-model="variable.default"
                    />
                  </div>
                </div>
              </div>
            </div>
          </api-srvvar>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";

Vue.component("api-srvvar", {
  props: ["name", "variable", "server"],
  computed: {
    variableName: {
      get: function() {
        return this.name;
      },
      set: function(newVal) {
        this.$parent.renameVariable(this.server, this.name, newVal);
      },
    },
  },
  methods: {
    removeVariable: function() {
      this.$parent.removeVariable(this.server, this.name);
    },
    addVEnum: function() {
      console.log("addVEnum");
      if (!this.variable.enum) {
        this.$set(this.variable, "enum", []);
      }
      this.variable.enum.push("newValue");
    },
  },
  data: function() {
    return {};
  },
});

Vue.component("api-venum", {
  props: ["variable", "eindex"],
  computed: {
    vename: {
      get: function() {
        return this.variable.enum[this.eindex];
      },
      set: function(newValue) {
        this.variable.enum[this.eindex] = newValue;
      },
    },
  },
  methods: {
    removeVEnum: function(eIndex) {
      this.variable.enum.splice(eIndex, 1);
    },
    addVEnum: function() {
      this.$parent.addVEnum();
    },
  },
  data: function() {
    return {};
  },
});

export default {
  props: ["openapi"],
  created() {},
  methods: {
    specLink: function(fragment) {
      return this.$parent.specLink(fragment);
    },
    addServer: function() {
      console.log(this.openapi);
      if (!this.openapi.servers) {
        this.$set(this.openapi, "servers", []);
      }
      this.openapi.servers.push({ url: "", description: "" });
    },
    removeServer: function(index) {
      this.openapi.servers.splice(index, 1);
    },

    addVariable: function(serverIndex) {
      if (!this.openapi.servers[serverIndex].variables)
        this.$set(this.openapi.servers[serverIndex], "variables", {});
      this.$set(this.openapi.servers[serverIndex].variables, "newVar", {
        description: "newVar",
        default: "change-me",
      });
    },

    renameVariable: function(server, oldName, newName) {
      this.$set(server.variables, newName, server.variables[oldName]);
      Vue.delete(server.variables, oldName);
    },

    removeVariable: function(server, index) {
      Vue.delete(server.variables, index);
    },

    addSchema: function() {
      if (!this.openapi.components.schemas.NewSchema) {
        this.$set(this.openapi.components.schemas, "NewSchema", {
          type: "object",
        });
      }
    },

    duplicateSchema: function(key) {
      if (!this.openapi.components.schemas.NewSchema) {
        this.$set(
          this.openapi.components.schemas,
          "NewSchema",
          this.openapi.components.schemas[key]
        );
      }
    },
  },
};
</script>
<style scoped>
.server-content {
  margin-left: 1%;
  margin-top: 1%;
}
</style>
