<template>
  <div id="tab-security" class="tab-pane">
    <div class="panel-body-api">
      <div class="row">
        <div class="col-md-3">
          <button
            type="button"
            class="btn btn-w-m btn-primary"
            v-on:click="addSecurityDefinition()"
          >
            <i class="fa fa-plus"></i>&nbsp;Add Security Scheme
          </button>
          <br />

          <ul class="">
            <li style="margin-top: 2%; margin-left: -15%;"
              v-for="(sd, sdname, index) in openapi.components.securitySchemes"
              v-bind:key="index"
              class=""
            >
              <a :href="'#sec' + sdname">{{ sdname }}</a>
              <ul class="list-group clear-list m-t">
                <li
                  v-for="(flow, flowName, index) in sd.flows"
                  v-bind:key="index"
                  class="list-group-item fist-item"
                >
                  {{ flowName }}
                  <ul class="list-group clear-list m-t">
                    <li
                      v-for="(sdesc, sname, index) in flow.scopes"
                      v-bind:key="index"
                      class="list-group-item fist-item"
                    >
                      {{ sname }}
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="col-md-8">
          <api-secdef
            v-for="(sd, sdname) in openapi.components.securitySchemes"
            :key="sdname"
            :sd="sd"
            :sdname="sdname"
            :openapi="openapi"
            inline-template
          >
          <div>
            <br>
            <div class="row" >
              <a
                class="btn btn-white btn-bitbucket server-content"
                data-balloon="Add securityScheme" style="height: 5%;"
                v-on:click="addSecurityDefinition()"
                ><i class="fa fa-plus"></i
              ></a>
              <a
                class="btn btn-white btn-bitbucket server-content"
                data-balloon="Remove securityScheme" style="height: 5%; margin-left:1%"
                v-on:click="removeSecurityDefinition(sdname)"
                ><i class="fa fa-trash"></i
              ></a>
              </div>  
              <br>
              <div class="row" style="border: 1px solid; border-color: #f0f0f0;">  
              <div class="form-group col-md-4.5 security-content">
                <label>Name</label>
                <input
                  type="text"
                  placeholder="Name"
                  v-model.lazy="secname"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-5">
                <label>Type</label>
                <select class="form-control" v-model="type">
                  <option value="apiKey">API Key</option>
                  <option value="oauth2">oAuth2</option>
                  <option value="http">HTTP Authentication</option>
                  <option value="openIdConnect">OpenIdConnect</option>
                </select>
              </div>
              <div class="form-group">
                <label> Apply to All paths</label>
                <br />
                <input
                  type="checkbox" 
                  :value="sdname"
                  v-model="appliesToAllPaths"
                />
              </div>

              <div v-if="sd.type == 'http'" class="animate-if">
                <div class="form-group col-md-12" style="margin-left: -4%;">
                  <label>Scheme</label>
                  <input
                    type="text"
                    placeholder="Scheme"
                    v-model="sd.scheme"
                    class="form-control"
                  />
                </div>
                <div
                  v-if="sd.scheme && sd.scheme.toLowerCase() == 'bearer'"
                  class="field is-horizontal"
                >
                  <div class="form-group col-md-12" style="margin-left: -4%;">
                    <label>Bearer Format</label>
                    <input
                      type="text"
                      placeholder="Bearer Format"
                      v-model="sd.bearerFormat"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>

              <div v-if="sd.type == 'openIdConnect'" class="animate-if">
                <div class="form-group col-md-12" style="margin-left: -4%;">
                  <label>OpenIdConnect URL</label>
                  <input
                    type="text"
                    placeholder="OpenIdConnect URL"
                    v-model="sd.openIdConnectUrl"
                    class="form-control"
                  />
                </div>
              </div>

              <div v-if="sd.type == 'apiKey'" class="animate-if">
                <div class="form-group col-md-12" style="margin-left: -4%;">
                  <label>Parameter Name</label>
                  <input
                    type="text"
                    placeholder="Parameter Name"
                    v-model="sd.name"
                    class="form-control"
                  />
                </div>

                <div class="form-group col-md-12" style="margin-left: -4%;">
                  <label>Location</label>
                  <select class="form-control" v-model="sd.in">
                    <option value="header">Header</option>
                    <option value="query">Query</option>
                    <option value="cookie">Cookie</option>
                  </select>
                </div>
              </div>

              <div
                v-if="sd.type == 'oauth2'"
                class="animate-if"
                style="margin-left: 1%;"
              >
                <div class="col-sm-18">
                  <label class="checkbox-inline i-checks">
                    <input
                      type="checkbox"
                      v-model="hasImplicit"
                    />&nbsp;&nbsp;&nbsp;Implicit </label
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <label class="i-checks">
                    <input
                      type="checkbox"
                      v-model="hasPassword"
                    />&nbsp;&nbsp;&nbsp;Password </label
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <label class="i-checks">
                    <input
                      type="checkbox"
                      v-model="hasClientCred"
                    />&nbsp;&nbsp;&nbsp;clientCredentials </label
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <label class="i-checks">
                    <input
                      type="checkbox"
                      v-model="hasAuthCode"
                    />&nbsp;&nbsp;&nbsp;authorizationCode
                  </label>
                </div>
              </div>
            </div>
            </div>
          </api-secdef>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

Vue.component("api-secdef", {
  props: ["openapi", "sd", "sdname"],
  computed: {
    secname: {
      get: function() {
        return this.sdname;
      },
      set: function(newVal) {
        this.$parent.renameSecurityDefinition(this.sdname, newVal);
      },
    },
    type: {
      get: function() {
        return this.sd.type;
      },
      set: function(newVal) {
        this.sd.type = newVal;
        if (newVal != "apiKey") {
          Vue.delete(this.sd, "in");
          Vue.delete(this.sd, "name");
        }
        if (newVal != "oauth2") {
          Vue.delete(this.sd, "flows");
        }
        if (newVal != "http") {
          Vue.delete(this.sd, "scheme");
          Vue.delete(this.sd, "bearerFormat");
        }
      },
    },
    appliesToAllPaths: {
      get: function() {
        var index = -1;
        for (var s = 0; s < this.openapi.security.length; s++) {
          var sr = this.openapi.security[s];
          if (typeof sr[this.sdname] !== "undefined") {
            index = s;
          }
        }
        return index >= 0 ? true : false;
      },
      set: function(newVal) {
        if (newVal) {
          if (!this.openapi.security) {
            Vue.set(this.openapi, "security", []);
          }
          var newSr = {};
          newSr[this.sdname] = [];
          for (var s in this.sd.scopes) {
            newSr[this.sdname].push(s);
          }
          this.openapi.security.push(newSr);
        } else {
          this.$parent.filterSecurityDefinition(
            this.openapi.security,
            this.sdname
          );
        }
      },
    },
    hasImplicit: {
      get: function() {
        return this.sd.flows && this.sd.flows.implicit;
      },
      set: function(newVal) {
        if (this.sd.flows == undefined && newVal) {
          this.sd.flows = { implicit: {} };
        } else {
          Vue.delete(this.sd.flows, "implicit");
        }
      },
    },
    hasPassword: {
      get: function() {
        return this.sd.flows && this.sd.flows.password;
      },
      set: function(newVal) {
        if (this.sd.flows == undefined && newVal) {
          this.sd.flows = { password: {} };
        } else {
          Vue.delete(this.sd.flows, "password");
        }
      },
    },
    hasAuthCode: {
      get: function() {
        return this.sd.flows && this.sd.flows.authorizationCode;
      },
      set: function(newVal) {
        if (this.sd.flows == undefined && newVal) {
          this.sd.flows = { authorizationCode: {} };
        } else {
          Vue.delete(this.sd.flows, "authorizationCode");
        }
      },
    },
    hasClientCred: {
      get: function() {
        return this.sd.flows && this.sd.flows.clientCredentials;
      },
      set: function(newVal) {
        if (this.sd.flows == undefined && newVal) {
          this.sd.flows = { clientCredentials: {} };
        } else {
          Vue.delete(this.sd.flows, "clientCredentials");
        }
      },
    },
  },
  methods: {
    addSecurityDefinition: function() {
      this.$parent.addSecurityDefinition();
    },
    removeSecurityDefinition: function(sdname) {
      this.$parent.removeSecurityDefinition(sdname);
    },
    addScope: function(flow) {
      if (!flow.scopes) Vue.set(flow, "scopes", {});
      if (!flow.scopes.newScope) {
        Vue.set(flow.scopes, "newScope", "description");
      }
    },
    renameScope: function(flow, oldName, newName) {
      Vue.set(flow.scopes, newName, flow.scopes[oldName]);
      Vue.delete(flow.scopes, oldName);
    },
    removeScope: function(flow, sName) {
      this.$parent.save();
      Vue.delete(flow.scopes, sName);
    },
  },
  data: function() {
    return {};
  },
});

export default {
  props: ["openapi"],
  methods: {
    addSecurityDefinition: function() {
      if (!this.openapi.components.securitySchemes) {
        Vue.set(this.openapi.components, "securitySchemes", {});
      }
      if (!this.openapi.components.securitySchemes.newSecurityScheme) {
        var newSecDef = {};
        newSecDef.type = "apiKey";
        newSecDef.name = "api_key";
        newSecDef.in = "query";
        Vue.set(
          this.openapi.components.securitySchemes,
          "newSecurityScheme",
          newSecDef
        );
      }
    },

    renameSecurityDefinition: function(oldName, newName) {
      Vue.set(
        this.openapi.components.securitySchemes,
        newName,
        this.openapi.components.securitySchemes[oldName]
      );
      Vue.delete(this.openapi.components.securitySchemes, oldName);
    },

    filterSecurityDefinition: function(security, sdname) {
      var index = -1;
      for (var s = 0; s < security.length; s++) {
        var sr = security[s];
        if (typeof sr[sdname] !== "undefined") {
          index = s;
        }
      }
      if (index >= 0) {
        security.splice(index, 1);
      }
    },

    removeSecurityDefinition: function(index) {
      this.$parent.save();
      Vue.delete(this.openapi.components.securitySchemes, index);
      this.filterSecurityDefinition(this.openapi.security, index);
      for (var p in this.openapi.paths) {
        var path = this.openapi.paths[p];
        for (var o in path) {
          var op = path[o];
          if (op.security) {
            this.filterSecurityDefinition(op.security, index);
          }
        }
      }
    },
  },
};
</script>
>\
