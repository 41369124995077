<template>
  <div class="col-lg-9 animated fadeInRight">
    <div class="col-lg-12">
      <div class="tabs-container">
        <div class="tabs-left">
          <ul class="nav nav-tabs">
            <li>
              <a class="nav-link active" data-toggle="tab" href="#tab-upload"><i class="fa fa-upload" aria-hidden="true"></i> Upload</a>
            </li>
            <li>
              <a class="nav-link" data-toggle="tab" href="#tab-header"><i class="fa fa-header" aria-hidden="true"></i> Header</a>
            </li>
            <li>
              <a class="nav-link" data-toggle="tab" href="#tab-server"><i class="fa fa-server" aria-hidden="true"></i> Servers</a>
            </li>
            <li>
              <a class="nav-link" data-toggle="tab" href="#tab-security"><i class="fa fa-lock" aria-hidden="true"></i> Security</a>
            </li>
            <li>
              <a class="nav-link" data-toggle="tab" href="#tab-tags"><i class="fa fa-tag" aria-hidden="true"></i> Tags</a>
            </li>
            <li>
              <a class="nav-link" data-toggle="tab" href="#tab-main"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Main</a>
            </li>
            <li>
              <a class="nav-link" data-toggle="tab" href="#tab-schemas"><i class="fa fa-code" aria-hidden="true"></i> Schemas</a>
            </li>
            <li>
              <a class="nav-link" data-toggle="tab" href="#tab-json" @click="renderOutput($event)"><i class="fa fa-cloud-download" aria-hidden="true"></i> Export JSON</a>
            </li>
            <li>
              <a class="nav-link" data-toggle="tab" href="#tab-yaml" @click="renderOutputYaml($event)"><i class="fa fa-cloud-download" aria-hidden="true"></i> Export YAML</a>
            </li>
          </ul>
          <div class="tab-content">
            <Upload></Upload>
            <Header :openapi="openapi"></Header>
            <servers :openapi="openapi"></servers>
            <Security :openapi="openapi"></Security>
            <Tags :openapi="openapi"></Tags>
            <Main :openapi="openapi"></Main>
            <Schemas :openapi="openapi"></Schemas>
            <export-json :openapi="openapi"></export-json>
            <ExportYaml :openapi="openapi"></ExportYaml>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Upload from "../../../framework/api/Upload";
import Header from "../../../framework/api/Header";
import Servers from "../../../framework/api/Servers";
import Security from "../../../framework/api/Security";
import Tags from "../../../framework/api/Tags";
import Main from "../../../framework/api/Main";
import Schemas from "../../../framework/api/Schemas";
import ExportJson from "../../../framework/api/ExportJson";
import ExportYaml from "../../../framework/api/ExportYaml";

export default {
  props: ["isScratch", "openapi", "sd", "sdname"],
  data() {
    return {};
  },
  components: {
    Upload,
    Header,
    Servers,
    Security,
    Tags,
    Main,
    ExportJson,
    Schemas,
    ExportYaml,
  },
  watch: {
    isScratch() {},
  },
  methods: {
    specLink: function (fragment) {
      return this.$parent.specLink(fragment);
    },
    save: function () {
      this.$parent.save();
    },
    renderOutput: function ($event) {
      this.selectTab("output", $event);
      $("#json-output").html('<pre class="prettyprint" style="background-color: none; border: none; background-color: none;" ><code id="pretty-json" style="white-space: pre; overflow: scroll; background-color: none;"></code></pre>');
      var def = this.$parent.postProcessDefinition(this.openapi);
      const output = JSON.stringify(def, null, 4);
      $("#pretty-json").html(output);
      const clippy = new Clipboard("#copy-json");
      setTimeout(function () {
        $("pre code").each(function (i, block) {
          console.log(block);
          hljs.highlightBlock(block);
        });
      }, 0);
      var data = "text/json;charset=utf-8," + encodeURIComponent(output);
      $("#download-output").attr("href", "data:" + data);
      $("#download-output").attr("download", "openapi.json");
    },
    selectTab: function (name, $event) {
      $(".tabItem").removeClass("is-active");
      $("#tabItem-" + name).addClass("is-active");
      $(".tab-pane").addClass("hidden");
      $("#" + name).removeClass("hidden");
      $event.preventDefault();
    },
    renderOutputYaml: function ($event) {
      this.selectTab("yaml", $event);
      $("#yaml-output").html('<pre class="prettyprint" style="background-color: none; border: none; background-color: none;" ><code id="pretty-yaml" style="white-space: pre; overflow: scroll; background-color: none;"></code></pre>');
      var def = this.$parent.postProcessDefinition(this.openapi);
      const output = jsyaml.dump(def);
      $("#pretty-yaml").html(output);
      const clippy = new Clipboard("#copy-yaml");
      setTimeout(function () {
        $("pre code").each(function (i, block) {
          hljs.highlightBlock(block);
        });
      }, 0);
      var data = "text/x-yaml;charset=utf-8," + encodeURIComponent(output);
      $("#download-yaml").attr("href", "data:" + data);
      $("#download-yaml").attr("download", "openapi.yaml");
    },
  },
};
</script>
<style scoped>
.tabs-container .tabs-left > .nav-tabs > li > a {
  margin-right: 29px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
</style>
