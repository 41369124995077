<template>
  <div id="tab-schemas" class="tab-pane">
    <div class="panel-body-api">
      <div class="row">
        <div :class="Object.keys(openapi.components.schemas).length == 0 ? 'col-md-6' : ''">
          <div class="card-content" v-if="Object.keys(openapi.components.schemas).length == 0">
            <p class="title">
              <a v-bind:href="specLink('#schemasObject')" target="_blank">OpenAPI Specification Schemas Object</a>
            </p>
            <div class="hr-line-dashed"></div>
            <span> There are no shared schemas defined yet in this document. </span>
            <br /><br />
            <button v-on:click="addSchema()" type="button" class="btn btn-w-m btn-primary"><i class="fa fa-fw fa-plus"></i> Create Schema</button>
          </div>
        </div>

        <div class="col-md-12" v-for="(schema, key, index) in openapi.components.schemas" :id="'schema_' + key" v-bind:key="index">
          <div class="row" :id="index">
            <a data-toggle="tooltip" data-placement="top" title="Add schema" style="margin-top: 1%;" class="btn btn-white btn-bitbucket main-content" v-on:click="addSchema()">
              <i class="fa fa-plus"></i>
            </a>

            <a data-toggle="tooltip" data-placement="top" title="Duplicate schema" style="margin-top: 1%;" class="btn btn-white btn-bitbucket main-content" v-on:click="duplicateSchema(key)">
              <i class="fa fa-copy"></i>
            </a>

            <a data-toggle="tooltip" data-placement="top" title="Edit shared schema" style="margin-top: 1%;" class="btn btn-white btn-bitbucket main-content" v-on:click="editSchema(key)">
              <i class="fa fa-edit"></i>
            </a>

            <a data-toggle="tooltip" data-placement="top" title="Remove schema" style="margin-top: 1%;" class="btn btn-white btn-bitbucket main-content" v-on:click="removeSchema(key)">
              <i class="fa fa-trash"></i>
            </a>
            <input class="form-control main-content col-md-6" placeholder="Required" style="margin-top: 1%;" v-on:focus="storeSchemaName(key)" v-on:change="renameSchema(key)" v-model.lazy="key" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
export default {
  props: ["openapi", "index"],
  data() {
    return {
      currentSchema: "",
    };
  },
  methods: {
    specLink: function (fragment) {
      return this.$parent.specLink(fragment);
    },
    addSchema: function () {
      if (!this.openapi.components.schemas.NewSchema) {
        Vue.set(this.openapi.components.schemas, "NewSchema", { type: "object" });
      }
    },

    duplicateSchema: function (key) {
      if (!this.openapi.components.schemas.NewSchema) {
        Vue.set(this.openapi.components.schemas, "NewSchema", this.openapi.components.schemas[key]);
      }
    },

    editSchema: function (key) {
      var initial = deref(this.openapi.components.schemas[key], this.openapi);
      var editorOptions = {};

      const container = document.getElementById("schemaContainer");
      const options = {};
      const editor = new JSONEditor(container, editorOptions);
      editor.set(initial);
      schemaEditorClose = function () {
        editor.destroy();
        $("#myModal").modal("hide");
      }.bind(this);
      schemaEditorSave = function () {
        Vue.set(this.openapi.components.schemas, key, editor.get());
        schemaEditorClose();
      }.bind(this);
      $("#schemaModalTitle").text("Schema Editor " + key);
      $("#myModal").modal("show");
    },

    removeSchema: function (key) {
      Vue.delete(this.openapi.components.schemas, key);
    },

    storeSchemaName: function (key) {
      this.currentSchema = key;
    },

    renameSchema: function (key) {
      Vue.set(this.openapi.components.schemas, key, this.openapi.components.schemas[this.currentSchema]);
      Vue.delete(this.openapi.components.schemas, this.currentSchema);
    },
  },
};
</script>
