<template>
  <div id="tab-main" class="tab-pane">
    <div class="panel-body-api">
      <div class="row">
        <div :class="Object.keys(openapi.paths).length == 0 ? 'col-md-6' : ''">
          <!-- <ul class="list-group clear-list">
            <li
              v-for="(path, key) in openapi.paths"
              v-bind:key="key"
              class="list-group-item fist-item"
            >
              <a
                :href="path.id"
                target="_self"
                v-on:click="showResource(key)"
                >{{ key }}</a
              >

              <ul class="list-group clear-list">
                <li
                  v-for="(m, mkey) in path"
                  v-bind:key="mkey"
                  class="list-group-item fist-item"
                >
                  <template
                    v-if="
                      '.get.post.put.patch.head.options.delete.trace'.indexOf(
                        mkey
                      ) > 0
                    "
                  >
                    <a
                      :href="path.id"
                      target="_self"
                      v-on:click="showResource(key)"
                      >{{ key }}</a
                    >
                  </template>
                </li>
              </ul>
            </li>
          </ul>
            -->
          <div class="card-content" v-if="Object.keys(openapi.paths).length == 0">
            <p class="title">
              <a v-bind:href="specLink('#pathsObject')" target="_blank">OpenAPI Specification Paths Object</a>
            </p>
            <div class="hr-line-dashed"></div>
            <span> There are no paths defined yet for this definition. </span>
            <br /><br />
            <button v-on:click="addResource()" type="button" class="btn btn-w-m btn-primary"><i class="fa fa-fw fa-plus"></i> Create Path</button>
          </div>
        </div>
        <div class="col-md-12" v-for="(path, key, index) in openapi.paths" v-bind:key="index">
          <api-resource :openapi="openapi" :path="path" :index="key" :maintags="openapi.tags" inline-template>
            <div class="row" :id="sanitisePath()">
              <a data-toggle="tooltip" data-placement="top" title="Add Path" class="btn btn-white btn-bitbucket main-content" v-on:click="addResource()">
                <i class="fa fa-plus"></i>
              </a>

              <a data-toggle="tooltip" data-placement="top" title="Duplicate Path" class="btn btn-white btn-bitbucket main-content" v-on:click="duplicateResource(pathEntry)">
                <i class="fa fa-copy"></i>
              </a>

              <a data-toggle="tooltip" data-placement="top" title="Edit Path Description" class="btn btn-white btn-bitbucket main-content" v-on:click="editPathDesc()">
                <i class="fa fa-edit"></i>
              </a>

              <a data-toggle="tooltip" data-placement="top" title="Remove Path" class="btn btn-white btn-bitbucket main-content" v-on:click="removePath(pathEntry)">
                <i class="fa fa-trash"></i>
              </a>
              <span class="main-content">Path:</span>
              <input class="form-control main-content col-md-6" placeholder="Required" v-model.lazy="pathEntry" />
              <button type="button" class="btn btn-w-m btn-primary main-content" v-on:click="addOperation()">Add Operation</button>

              <div v-for="(method, key, index) in httpMethods" v-bind:key="index">
                <api-method :openapi="openapi" :method="method" :index="key" :maintags="maintags" inline-template>
                  <div class="panel panel-default" style="margin-top: 1%">
                    <div class="panel-heading">
                      <h5 class="panel-title">
                        <a data-toggle="collapse" data-parent="#accordion" :href="'#' + index">
                          <i class="fa fa-reorder" data-toggle="tooltip" data-placement="top" title="Toggle Operation"></i>
                        </a>

                        <i class="fa fa-plus main-content" data-toggle="tooltip" data-placement="top" title="Add Operation" v-on:click="addOperation()"></i>

                        <i data-toggle="tooltip" data-placement="top" title="Duplicate Operation" class="fa fa-fw fa-copy main-content" v-on:click="duplicateOperation(method)"></i>

                        <i data-toggle="tooltip" data-placement="top" title="Remove Operation" class="fa fa-fw fa-trash main-content" v-on:click="removeOperation(httpMethod)"></i>
                        <span class="methodNameSpan">{{ httpMethod }} {{ method.operationId }}</span>
                      </h5>
                    </div>
                    <div :id="index" class="panel-collapse in collapse showpanel-collapse in collapse show" style="margin-top: 1%">
                      <div class="tabs-container">
                        <ul class="nav nav-tabs">
                          <li>
                            <a class="nav-link active" data-toggle="tab" :href="'#tab-main-config' + index"> <i class="fa fa-cog"></i><span class="p-vsm">Main</span></a>
                          </li>
                          <li>
                            <a class="nav-link" data-toggle="tab" :href="'#tab-description' + index"><i class="fa fa-comment"></i><span class="p-vsm">Description</span></a>
                          </li>
                          <li>
                            <a class="nav-link" data-toggle="tab" :href="'#tab-docs' + index"><i class="fa fa fa-book"></i><span class="p-vsm">Docs</span></a>
                          </li>
                          <li>
                            <a class="nav-link" data-toggle="tab" :href="'#tab-parameters' + index"><i class="fa fa-list"></i><span class="p-vsm">Parameters</span></a>
                          </li>
                          <li>
                            <a class="nav-link" data-toggle="tab" :href="'#tab-request' + index"><i class="fa fa-question"></i><span class="p-vsm">Request Body</span></a>
                          </li>
                          <li>
                            <a class="nav-link" data-toggle="tab" :href="'#tab-response' + index"><i class="fa fa-thumbs-o-up"></i><span class="p-vsm">Responses</span></a>
                          </li>
                          <li>
                            <a class="nav-link" data-toggle="tab" :href="'#tab-security' + index"><i class="fa fa-lock"></i><span class="p-vsm">Security</span></a>
                          </li>
                        </ul>
                        <div class="tab-content">
                          <div :id="'tab-main-config' + index" class="tab-pane active">
                            <div class="panel-body panel-body-operation row">
                              <div class="form-group col-md-3">
                                <label>Method</label>
                                <select class="form-control m-b" v-model="httpMethod">
                                  <option value="GET">GET</option>
                                  <option value="POST">POST</option>
                                  <option value="PUT">PUT</option>
                                  <option value="DELETE">DELETE</option>
                                  <option value="HEAD">HEAD</option>
                                  <option value="PATCH">PATCH</option>
                                  <option value="OPTIONS">OPTIONS</option>
                                  <option value="TRACE">TRACE</option>
                                </select>
                              </div>

                              <div class="form-group col-md-5">
                                <label>Operation Id</label>
                                <input type="text" placeholder="Operation Id" v-model="method.operationId" class="form-control" />
                              </div>
                              <div class="checkbox checkbox-primary" style="margin-top: 4%">
                                <input type="checkbox" id="inlineCheckbox2" v-model="method.deprecated" />
                                <label for="inlineCheckbox2"> Deprecated </label>
                              </div>
                            </div>
                          </div>
                          <div :id="'tab-description' + index" class="tab-pane">
                            <div class="panel-body panel-body-operation">
                              <div class="form-group row">
                                <label class="col-sm-1 col-form-label">Summary</label>
                                <div class="col-sm-8">
                                  <input type="text" v-model="method.summary" class="form-control" />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-sm-1 col-form-label">Description</label>
                                <div class="col-sm-8">
                                  <textarea v-model="method.description" class="form-control" placeholder="" rows="8"></textarea>
                                </div>
                              </div>
                              <multiselect v-model="vtags" placeholder="Add a Tags" :options="mtags" :multiple="true" :taggable="true"></multiselect>
                            </div>
                          </div>
                          <div :id="'tab-docs' + index" class="tab-pane">
                            <div class="panel-body panel-body-operation">
                              <div class="form-group col-md-8">
                                <label>Docs Description</label>
                                <textarea v-model="method.externalDocs.description" class="form-control" placeholder="" rows="8"></textarea>
                              </div>

                              <div class="form-group col-md-8">
                                <label>Docs URL</label>
                                <input type="text" v-model="method.externalDocs.url" class="form-control" />
                              </div>
                            </div>
                          </div>

                          <div :id="'tab-parameters' + index" class="tab-pane">
                            <div v-if="method.parameters.length == 0">
                              <div class="card-content">
                                <p class="title" style="margin-left: 1%; margin-top: 1%">
                                  <a v-bind:href="specLink('#parametersObject')" target="_blank">OpenAPI Specification Parameters Object</a>
                                </p>
                                <div class="hr-line-dashed" style="margin: 9px 0"></div>
                                <span style="margin-left: 1%"> There are no parameters defined for this operation. </span>
                              </div>
                              <br />
                              <button v-on:click="addParameter()" type="button" style="margin-left: 2%" class="btn btn-w-m btn-primary"><i class="fa fa-fw fa-plus"></i> Create Parameter</button>
                              <br /><br /><br />
                            </div>

                            <div v-for="(parameter, index) in method.parameters" v-bind:key="index">
                              <api-parameter :parameter="parameter" :index="index" inline-template>
                                <div class="panel panel-default" style="border-color: #ffffff">
                                  <div class="panel-heading">
                                    <h5 class="panel-title">
                                      <a data-toggle="collapse" data-parent="#accordion" :href="'#collapse' + index"><i class="fa fa-reorder" data-toggle="tooltip" data-placement="top" title="Toggle Operation"></i> </a>
                                      <span class="main-content">Parameter: </span>
                                      <i class="fa fa-plus main-content" data-toggle="tooltip" data-placement="top" title="Add Operation" v-on:click="addParameter()"></i>

                                      <i data-toggle="tooltip" data-placement="top" title="Duplicate Operation" class="fa fa-fw fa-copy main-content" v-on:click="addParameter()"></i>

                                      <i data-toggle="tooltip" data-placement="top" title="Remove Operation" class="fa fa-pencil-square-o main-content" v-on:click="editSchema()"></i>

                                      <i data-toggle="tooltip" data-placement="top" title="Remove Operation" class="fa fa-fw fa-trash main-content" v-on:click="removeParameter()"></i>
                                      <span class="methodNameSpan">{{ parameter.name }}</span>
                                    </h5>
                                  </div>
                                  <div :id="'collapse' + index" class="panel-collapse in collapse">
                                    <div class="panel-body panel-body-operation" style="border: none">
                                      <div class="form-group col-md-8">
                                        <label>Parameter Name</label>
                                        <input type="text" placeholder="Parameter Name" v-model="parameter.name" class="form-control" />
                                      </div>

                                      <div class="form-group col-md-8">
                                        <label>Description</label>
                                        <textarea v-model="parameter.description" :id="descId" class="form-control" placeholder="" rows="3"></textarea>
                                      </div>
                                      <div class="row" style="margin-left: 0%">
                                        <div class="form-group col-md-3">
                                          <label>Location</label>
                                          <select class="form-control m-b" v-model="effectiveIn">
                                            <option value="path">Path</option>
                                            <option value="query">Query</option>
                                            <option value="header">Header</option>
                                            <option value="cookie">Cookie</option>
                                          </select>
                                        </div>

                                        <div class="form-group col-md-3">
                                          <label>Data Type</label>
                                          <select class="form-control m-b" v-model="effectiveType">
                                            <option value="boolean">Boolean</option>
                                            <option value="string">String</option>
                                            <option value="integer">Integer</option>
                                            <option value="number">Number</option>
                                            <option value="array">Array</option>
                                            <option value="object">Object</option>
                                          </select>
                                        </div>
                                      </div>

                                      <div class="row" style="margin-left: 0%">
                                        <div class="checkbox checkbox-circle">
                                          <input :id="'effectiveRequired' + index" type="checkbox" v-model="effectiveRequired" />
                                          <label :for="'effectiveRequired' + index"> Required </label>
                                        </div>
                                        <div class="checkbox checkbox-circle">
                                          <input :id="'deprecated' + index" type="checkbox" v-model="parameter.deprecated" />
                                          <label :for="'deprecated' + index"> Deprecated </label>
                                        </div>

                                        <div class="checkbox checkbox-circle">
                                          <input :id="'allowEmptyValue' + index" type="checkbox" v-model="parameter.allowEmptyValue" />
                                          <label :for="'allowEmptyValue' + index"> Allow&nbsp;Empty&nbsp;Value </label>
                                        </div>
                                      </div>
                                      <div class="row" style="margin-left: 0%; margin-top: 1%">
                                        <div class="form-group col-md-3">
                                          <label>Default Value</label>
                                          <input type="text" placeholder="Default Value" v-model="parameter.schema.default" class="form-control" />
                                        </div>

                                        <div class="form-group col-md-3">
                                          <label>Format</label>
                                          <input type="text" placeholder="Format" v-model="parameter.format" :list="formatListId" class="form-control" />
                                          <datalist :id="formatListId">
                                            <option v-for="(option, index) in effectiveFormats" v-bind:key="index">{{ option }}</option>
                                          </datalist>
                                        </div>
                                      </div>

                                      <div class="row" style="margin-left: 0%; margin-top: 0%">
                                        <div class="form-group col-md-3">
                                          <label>Min. Length</label>
                                          <input type="number" min="0" step="1" v-model="parameter.minLength" class="form-control" />
                                        </div>

                                        <div class="form-group col-md-3">
                                          <label>Max. Length</label>
                                          <input type="number" min="0" step="1" v-model="parameter.maxLength" class="form-control" />
                                        </div>

                                        <div class="form-group col-md-3">
                                          <label>Pattern</label>
                                          <input type="text" v-model="parameter.pattern" class="form-control" />
                                        </div>
                                      </div>

                                      <button class="btn btn-default" type="button" v-on:click="addEnum()" style="margin-left: 2%"><i class="fa fa-plus"></i>&nbsp;Add&nbsp;Enum</button>

                                      <div v-if="!isComplex() && parameter.schema && parameter.schema.enum && parameter.schema.enum.length">
                                        <div class="row" style="margin-left: 2%" v-for="(evalue, eindex) in parameter.schema.enum" v-bind:key="eindex">
                                          <a class="icon" style="margin-top: 2%; margin-left: 1%" data-balloon="Add Enum value" v-on:click="addEnum()"><i class="fa fa-plus"></i></a>
                                          <a class="icon" style="margin-top: 2%; margin-left: 1%" data-balloon="Remove Enum value" v-on:click="removeEnum(eindex)"><i class="fa fa-trash"></i></a>

                                          <label style="margin-top: 2%; margin-left: 1%">Enum Value</label>
                                          <input type="text" style="margin-left: 2%" v-model="parameter.schema.enum[eindex]" class="form-control col-md-4" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </api-parameter>
                            </div>
                          </div>

                          <div :id="'tab-request' + index" class="tab-pane">
                            <div class="panel-body panel-body-operation">
                              <div v-if="!method.requestBody">
                                <div class="card-content">
                                  <p class="title" style="margin-left: -1%; margin-top: -1%">
                                    <a v-bind:href="specLink('#requestBodyObject')" target="_blank">OpenAPI Specification requestBody Object</a>
                                  </p>
                                  <div class="hr-line-dashed" style="margin: 9px -14px"></div>
                                  <span style="margin-left: -1%"> There is no requestBody defined. RequestBodies on GET and DELETE operations SHALL be ignored. </span>
                                </div>
                                <br />
                                <button v-on:click="addRequestBody()" type="button" style="margin-left: -1%" class="btn btn-w-m btn-primary"><i class="fa fa-fw fa-plus"></i> Create requestBody</button>
                                <br /><br /><br />
                              </div>
                              <div v-if="method.requestBody">
                                <div class="form-group col-md-8">
                                  <label>Description</label>
                                  <textarea v-model="effectiveRequestBody.description" class="form-control" placeholder="" rows="5"></textarea>
                                </div>

                                <div class="row">
                                  <div class="checkbox checkbox-circle" style="margin-left: 2%">
                                    <input :id="'requestRequired' + index" type="checkbox" v-model="effectiveRequestBody.required" />
                                    <label :for="'requestRequired' + index"> Required </label>
                                  </div>
                                  <button v-on:click="removeRequestBody()" type="button" style="margin-left: 1%; margin-top: -1%" class="btn btn-w-m btn-primary"><i class="fa fa-fw fa-trash"></i> Remove Request Body</button>
                                </div>

                                <api-mediatype v-for="(content, mediatype) in effectiveRequestBody.content" :content="content" :key="mediatype" :mediatype="mediatype" :container="effectiveRequestBody" inline-template>
                                  <div class="row">
                                    <i class="fa fa-plus" data-toggle="tooltip" data-placement="top" title="Add media-type" style="margin-left: 4%; margin-top: 1%" v-on:click="addMediaType()"></i>

                                    <i data-toggle="tooltip" data-placement="top" title="Duplicate media-type" class="fa fa-fw fa-copy" style="margin-left: 1%; margin-top: 1%" v-on:click="duplicateMediaType()"></i>

                                    <i data-toggle="tooltip" data-placement="top" title="Schema Tooltip" class="fa fa-pencil-square-o" style="margin-left: 1%; margin-top: 1%" v-on:click="editMediaType(mediatype)"></i>

                                    <span data-toggle="tooltip" data-placement="top" title="Re-use shared schema" style="margin-left: 1%; margin-top: 1%"
                                      ><a v-on:click="attachSchema()"><i class="fa fa-fw fa-recycle"></i></a
                                    ></span>
                                    <span :id="_uid" class="select hidden" style="margin-left: 1%; margin-top: 1%">
                                      <select v-model="selectedSchema">
                                        <option v-for="(schemaVal, schema, index) in $parent.$parent.$parent.$parent.$parent.container.openapi.components.schemas" :value="schema" v-bind:key="index">{{ schema }}</option>
                                      </select>
                                    </span>

                                    <i data-toggle="tooltip" data-placement="top" title="Remove media-type" style="margin-left: 1%; margin-top: 1%" class="fa fa-fw fa-trash" v-on:click="removeMediaType()"></i>
                                    <span style="margin-left: 1%; margin-top: 1%">Media-Type</span>
                                    <input style="margin-left: 1%; margin-top: 1%" type="text" placeholder="Required" v-model.lazy="mediaTypeName" class="form-control col-md-4" />
                                  </div>
                                </api-mediatype>
                              </div>
                            </div>
                          </div>

                          <div :id="'tab-response' + index" class="tab-pane">
                            <div class="panel-body panel-body-operation">
                              <api-response v-for="(response, status) in method.responses" :response="response" :key="status" :status="status" :method="method" inline-template>
                                <div style="border: 1px solid rgb(240, 240, 240)">
                                  <div class="row">
                                    <i class="fa fa-plus" data-toggle="tooltip" data-placement="top" title="Add response" style="margin-left: 4%; margin-top: 2%" v-on:click="addResponse()"></i>
                                    <i data-toggle="tooltip" data-placement="top" title="Duplicate response" class="fa fa-fw fa-copy" style="margin-left: 1%; margin-top: 2%" v-on:click="duplicateResponse()"></i>
                                    <i data-toggle="tooltip" data-placement="top" title="Remove response" style="margin-left: 1%; margin-top: 2%" class="fa fa-fw fa-trash" v-on:click="removeResponse()"></i>
                                    <span style="margin-left: 1%; margin-top: 2%">Status&nbsp;Code</span>
                                    <input style="margin-left: 1%; margin-top: 1%" type="text" v-model.lazy="statusCode" class="form-control col-md-3" />
                                    <span style="margin-left: 1%; margin-top: 2%">&nbsp;Description</span>
                                    <input style="margin-left: 1%; margin-top: 1%" type="text" v-model="response.description" class="form-control col-md-4" />
                                  </div>
                                  <br />
                                  <div class="card-content" v-if="!response.content || Object.keys(response.content).length == 0">
                                    <p class="title" style="margin-left: 2%; margin-top: -1%">
                                      <a v-bind:href="specLink('#mediaTypeObject')" target="_blank">OpenAPI Specification MediaType Object</a>
                                    </p>
                                    <div class="hr-line-dashed" style="margin: 8px 9px"></div>
                                    <span style="margin-left: 2%"> There are no media-types defined (they are optional). </span>
                                    <br />
                                    <button @click="addMediaType()" type="button" style="margin-left: 2%; margin-top: 1%" class="btn btn-w-m btn-primary"><i class="fa fa-fw fa-plus"></i> Create MediaType</button>
                                  </div>
                                  <br />
                                  <api-mediatype-response v-for="(content, mediatype) in response.content" :content="content" :key="mediatype" :mediatype="mediatype" :container="response" inline-template>
                                    <div class="row" style="margin-bottom: 1%; width: 98%; margin-left: 1%; padding: 1%; margin-top: -3%">
                                      <br />
                                      <i class="fa fa-plus" data-toggle="tooltip" data-placement="top" title="Add media-type" style="margin-left: 4%; margin-top: 2%" v-on:click="addMediaType()"></i>
                                      <i data-toggle="tooltip" data-placement="top" title="Duplicate media-type" class="fa fa-fw fa-copy" style="margin-left: 1%; margin-top: 2%" v-on:click="duplicateMediaType()"></i>
                                      <i data-toggle="tooltip" data-placement="top" title="Edit media-type" style="margin-left: 1%; margin-top: 2%" class="fa fa-fw fa-edit" v-on:click="editMediaType(mediatype)"></i>
                                      <i data-toggle="tooltip" data-placement="top" title="Remove media-type" style="margin-left: 1%; margin-top: 2%" class="fa fa-fw fa-trash" v-on:click="removeMediaType()"></i>
                                      <span style="margin-left: 1%; margin-top: 2%">Media-Type</span>
                                      <input style="margin-left: 1%; margin-top: 1%" type="text" :id="mediatype" v-model.lazy="mediaTypeName" class="form-control col-md-5" />
                                    </div>
                                  </api-mediatype-response>
                                </div>
                              </api-response>
                            </div>
                          </div>

                          <div :id="'tab-security' + index" class="tab-pane">
                            <div class="panel-body panel-body-operation">
                              <div class="row">
                                
                                <div class="radio">
                                  <input type="radio" :name="'group' + index" :id="'defaultradio' + index" value="default" v-model="secType" />
                                  <label :for="'defaultradio'+ index "> Use default security </label>
                                </div>

                                <div class="radio">
                                  <input type="radio" :name="'group' + index" :id="'noneradio' + index" value="none" v-model="secType" />
                                  <label :for="'noneradio' + index"> Disable security </label>
                                </div>

                                <div class="radio">
                                  <input type="radio" :name="'group' + index"  :id="'customradio' + index" value="custom" v-model="secType"  />
                                  <label :for="'customradio' + index"> Use custom security </label>
                                </div>
                               
                              </div>
                         

                              <div v-if="secType === 'custom'">
                                <h4 class="none"><strong>Selected security schemes</strong></h4>
                                <ul>
                                  <li v-for="(scheme, index) in method.security" v-bind:key="index">
                                    <span class="icon" data-balloon="Remove security scheme from operation"
                                      ><a v-on:click="removeSecScheme(index)"><i class="fa fa-trash"></i></a
                                    ></span>
                                    <span
                                      ><strong>{{ Object.keys(scheme)[0] }}</strong></span
                                    >
                                    <span>{{ Object.values(scheme)[0] }}</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </api-method>
              </div>
            </div>
          </api-resource>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";

export default {
  props: ["openapi"],
  components: { Multiselect },
  methods: {
    addResource: function () {
      if (!this.openapi.paths) Vue.set(this.openapi, "paths", {});
      if (!this.openapi.paths["/newPath"]) {
        Vue.set(this.openapi.paths, "/newPath", {});
        $("html,body").animate({ scrollTop: document.body.scrollHeight }, "fast");
      }
    },
    specLink: function (fragment) {
      return this.$parent.specLink(fragment);
    },
    showResource: function (key) {
      var target = "resource_" + key.split("/").join("").split("{").join("").split("}").join("");
      var e = document.getElementById(target);
      if (e) e.scrollIntoView();
    },
    save: function () {
      this.$parent.save();
    },
  },
};

Vue.component("api-resource", {
  props: ["openapi", "path", "index", "maintags"],
  computed: {
    pathEntry: {
      get: function () {
        return this.index;
      },
      set: function (newVal) {
        Vue.set(this.openapi.paths, newVal, this.openapi.paths[this.index]);
        Vue.delete(this.openapi.paths, this.index);
      },
    },
    httpMethods: function () {
      var result = {};
      for (var m in this.methods) {
        if (this.path[this.methods[m]]) {
          result[this.methods[m]] = this.path[this.methods[m]];
        }
      }
      return result;
    },
  },
  data: function () {
    return {
      methods: ["get", "post", "put", "delete", "patch", "head", "options", "trace"],
    };
  },
  methods: {
    sanitisePath: function () {
      return "resource_" + this.index.split("/").join("").split("{").join("").split("}").join("");
    },
    addResource: function () {
      this.$parent.addResource();
    },
    duplicateResource: function (index) {
      if (!this.openapi.paths["newPath"]) {
        Vue.set(this.openapi.paths, "/newPath", this.clone(this.openapi.paths[index]));
      }
    },
    clone: function (obj) {
      try {
        return JSON.parse(JSON.stringify(obj));
      } catch (ex) {
        return obj;
      }
    },
    removePath: function (target) {
      this.$parent.save();
      _.remove(this.openapi.paths, target);
      Vue.delete(this.openapi.paths, target);
    },
    editPathDesc: function () {
      $("#pathDesc" + this.sanitisePath()).toggleClass("hidden");
    },
    hidePathDesc: function () {
      $("#pathDesc" + this.sanitisePath()).addClass("hidden");
    },
    addOperation: function (template) {
      var index = 0;
      while (this.path[this.methods[index]] && index < this.methods.length) {
        index++;
      }
      if (index < this.methods.length) {
        var responses = {};
        responses.default = {
          description: "Default response",
        };
        var op = {};
        op.summary = (template && template.summary) || "";
        op.description = (template && template.description) || "";
        op.externalDocs = (template && template.externalDocs) || {};
        op.parameters = (template && template.parameters) || [];
        op.operationId = (template && template.operationId) || "";
        op.responses = (template && template.responses) || responses;
        Vue.set(this.path, this.methods[index], op);
      }
    },
    removeOperation: function (target) {
      this.$parent.save();
      Vue.delete(this.path, target.toLowerCase());
    },
    renameOperation: function (oldMethod, newMethod) {
      if (this.path[newMethod]) {
        Vue.set(this.path, "x-temp", this.path[newMethod]);
        Vue.delete(this.path, newMethod);
      }
      Vue.set(this.path, newMethod, this.path[oldMethod]);
      Vue.delete(this.path, oldMethod);
      if (this.path.temp) {
        Vue.set(this.path, oldMethod, this.path.temp);
        Vue.delete(this.path, "x-temp");
      }
    },
  },
});

Vue.component("api-method", {
  props: ["method", "index", "maintags", "openapi"],
  data: function () {
    return {
      visible: false,
      schemaEditor: undefined,
      cbName: undefined,
      expName: undefined,
    };
  },
  methods: {
    specLink: function (fragment) {
      return this.$parent.$parent.specLink(fragment);
    },
    markdownPreview: function () {
      this.$parent.$parent.markdownPreview("#" + this.descId);
    },
    toggleBody: function () {
      this.visible = !this.visible;
    },
    selectTab: function (name, $event) {
      $(".method-tab").removeClass("is-active");
      $(".tabItem-method-" + name).addClass("is-active");
      $(".method-pane").addClass("hidden");
      $(".method-pane-" + name).removeClass("hidden");
      $event.preventDefault();
    },
    addOperation: function () {
      this.$parent.addOperation();
    },
    duplicateOperation: function (method) {
      this.$parent.addOperation(method);
    },
    removeOperation: function (target) {
      this.$parent.removeOperation(target);
    },
    addParameter: function () {
      var newParam = {};
      newParam.name = "newParam";
      newParam.in = "query";
      newParam.required = false;
      newParam.schema = {};
      newParam.schema.type = "string";
      this.method.parameters.push(newParam);
    },
    removeParameter: function (index) {
      this.$parent.$parent.save();
      this.method.parameters.splice(index, 1);
    },
    addRequestBody: function () {
      if (!this.method.requestBody) {
        var rb = { required: false };
        rb.content = { "*/*": { schema: {} } };
        Vue.set(this.method, "requestBody", rb);
      }
    },
    removeRequestBody: function () {
      this.$parent.$parent.save();
      Vue.delete(this.method, "requestBody");
    },
    addResponse: function () {
      var status = 200;
      while (this.method.responses[status]) {
        status++;
      }
      var response = {};
      response.description = "Description";
      Vue.set(this.method.responses, status, response);
    },
    renameResponse: function (status, newVal) {
      Vue.set(this.method.responses, newVal, this.method.responses[status]);
      Vue.delete(this.method.responses, status);
    },
    addMediaType: function () {
      var rb = this.effectiveRequestBody;
      if (rb && rb.content && !rb.content["change/me"]) {
        Vue.set(rb.content, "change/me", { schema: {} });
      }
    },
    renameMediaType: function (oldName, newName) {
      var rb = this.effectiveRequestBody;
      Vue.set(rb.content, newName, rb.content[oldName]);
      Vue.delete(rb.content, oldName);
    },
    addCallback: function () {
      if (!this.method.callbacks) {
        Vue.set(this.method, "callbacks", {});
      }
      if (!this.method.callbacks.newCallback) {
        Vue.set(this.method.callbacks, "newCallback", { newExpression: {} });
      }
    },
    duplicateCallback: function (cbname) {
      if (!this.method.callbacks.newCallback) {
        Vue.set(this.method.callbacks, "newCallback", clone(this.method.callbacks[cbname]));
      }
    },
    removeCallback: function (cbname) {
      Vue.delete(this.method.callbacks, cbname);
    },
    storeCallbackName: function (oldName) {
      this.cbName = oldName;
    },
    renameCallback: function (newName) {
      Vue.set(this.method.callbacks, newName, this.method.callbacks[this.cbName]);
      Vue.delete(this.method.callbacks, this.cbName);
    },
    addCallbackURL: function (cbname) {
      if (!this.method.callbacks[cbname].newExpression) {
        Vue.set(this.method.callbacks[cbname], "newExpression", {});
      }
    },
    duplicateExpression: function (cbname, expname) {
      if (!this.method.callbacks[cbname].newExpression) {
        Vue.set(this.method.callbacks[cbname], "newExpression", clone(this.method.callbacks[cbname][expname]));
      }
    },
    removeExpression: function (cbname, expname) {
      Vue.delete(this.method.callbacks[cbname], expname);
    },
    storeExpressionName: function (oldName) {
      this.expName = oldName;
    },
    renameExpression: function (cbName, newName) {
      Vue.set(this.method.callbacks[cbName], newName, this.method.callbacks[cbName][this.expName]);
      Vue.delete(this.method.callbacks[cbName], this.expName);
    },
    addExpressionOperation: function (exp) {
      if (!exp.get) {
        Vue.set(exp, "get", {
          parameters: [],
          responses: { default: { description: "Default response" } },
        });
      }
    },
    removeSecScheme: function (index) {
      this.method.security.splice(index, 1);
      Vue.set(this.method, "security", this.method.security);
    },
  },
  computed: {
    httpMethod: {
      get: function () {
        return this.index.toUpperCase();
      },
      set: function (newVal) {
        this.$parent.renameOperation(this.index, newVal.toLowerCase());
      },
    },
    hashUid: function () {
      return "#" + this._uid;
    },
    descId: function () {
      return "txtOpDesc" + this._uid;
    },
    tagId: function () {
      return "tags-input" + this._uid;
    },
    hashTagId: function () {
      return "#" + this.tagId;
    },
    vtags: {
      get: function () {
        if (!this.method.tags) Vue.set(this.method, "tags", []);
        return this.method.tags;
      },
      set: function (newVal) {
        this.method.tags = newVal;
      },
    },
    mtags: {
      get: function () {
        var result = [];
        if (this.maintags) {
          for (var i = 0; i < this.maintags.length; i++) {
            result.push(this.maintags[i].name);
          }
        }
        return result;
      },
    },
    effectiveRequestBody: {
      get: function () {
        if (!this.method.requestBody) return null;
        if (!this.method.requestBody.$ref) return this.method.requestBody;
        return deref(this.method.requestBody, this.$parent.container.openapi, true);
      },
    },
    secType: {
      get: function () {
        if (!this.method.security) return "default";
        if (this.method.security && this.method.security.length === 0) return "none";
        return "custom";
      },
      set: function (newVal) {
        if (newVal == "default") {
          Vue.delete(this.method, "security");
        } else if (newVal == "none") {
          Vue.set(this.method, "security", []);
        } else {
          var newSec = clone(this.openapi.security);
          if (!newSec || newSec.length === 0) {
            newSec = [];
            for (var s in this.openapi.components.securitySchemes) {
              var scheme = this.openapi.components.securitySchemes[s];
              var scopes = [];
              if (scheme.type === "oauth2") {
                for (var f in scheme.flows) {
                  var flow = scheme.flows[f];
                  if (flow.scopes) {
                    for (sc in flow.scopes) {
                      if (scopes.indexOf(s) < 0) scopes.push(sc);
                    }
                  }
                }
              }
              var entry = {};
              entry[s] = scopes;
              newSec.push(entry);
              this.$forceUpdate();
            }
          }
          Vue.set(this.method, "security", newSec);
        }
      },
    },
  },
  beforeUpdate: function () {
    if (!this.method.externalDocs) {
      Vue.set(this.method, "externalDocs", {});
    }
  },
  beforeMount: function () {
    if (!this.method.externalDocs) {
      Vue.set(this.method, "externalDocs", {});
    }
  },
});

Vue.component("api-parameter", {
  props: ["parameter", "index"],
  computed: {
    hashUid: function () {
      return "#" + this._uid;
    },

    formatListId: function () {
      return "listFormats" + this._uid;
    },

    descId: function () {
      return "txtParmDesc" + this._uid;
    },

    effectiveType: {
      get: function () {
        if (!(this.parameter.schema && this.parameter.schema.type)) return "object";
        return this.parameter.schema.type;
      },
      set: function (newVal) {
        if (newVal == "array") {
          var items = {};
          items = clone(this.parameter.schema);
          Vue.set(this.parameter.schema, "items", items);
        } else {
          Vue.set(this.parameter, "schema", this.parameter.items.schema);
          Vue.delete(this.parameter.schema, "items");
        }
        this.parameter.schema.type = newVal;
      },
    },

    effectiveIn: {
      get: function () {
        if (!this.parameter.in) return "body";
        return this.parameter.in;
      },
      set: function (newVal) {
        this.parameter.in = newVal;
        if (newVal == "path") Vue.set(this.parameter, "required", true);
      },
    },

    effectiveRequired: {
      get: function () {
        if (typeof this.parameter.required === "undefined") return false;
        return this.parameter.required;
      },
      set: function (newVal) {
        this.parameter.required = newVal;
      },
    },

    effectiveFormats: {
      get: function () {
        if (this.parameter.schema.type == "integer") return ["int32", "int64"];
        if (this.parameter.schema.type == "number") return ["float", "double"];
        if (this.parameter.schema.type == "string") return ["date", "date-time", "byte", "binary", "password"];
        return [];
      },
      set: function (newVal) {},
    },

    schemaTooltip: {
      get: function () {
        if (!this.parameter.schema || !this.parameter.schema.$ref) {
          return "Edit inline schema";
        } else {
          var schemaName = this.parameter.schema.$ref.replace("#/components/schemas/", "");
          return "Edit shared schema (" + schemaName + ")";
        }
      },
    },
  },
  data: function () {
    return {
      visible: false,
      schemaEditor: undefined,
    };
  },
  methods: {
    markdownPreview: function () {
      this.$parent.$parent.$parent.markdownPreview("#" + this.descId);
    },
    toggleBody: function () {
      this.visible = !this.visible;
    },
    isComplex: function () {
      if (this.effectiveType === "object" || this.effectiveType === "array" || this.effectiveType === "file") {
        return true;
      }
      return false;
    },
    addParameter: function () {
      this.$parent.addParameter();
    },
    removeParameter: function () {
      this.$parent.removeParameter(this.index);
    },
    editSchema: function () {
      if (!this.parameter.schema) {
        Vue.set(this.parameter, "schema", {});
      }
      var initial = deref(this.parameter.schema, this.$parent.$parent.$parent.$parent.$parent.container.openapi);
      var editorOptions = {};
      const container = document.getElementById("schemaContainer");
      const options = {};
      const editor = new JSONEditor(container, editorOptions);
      editor.set(initial);
      schemaEditorClose = function () {
        editor.destroy();
        $("#myModal").modal("hide");
      }.bind(this);
      schemaEditorSave = function () {
        this.parameter.schema = editor.get();
        schemaEditorClose();
      }.bind(this);
      $("#schemaModalTitle").text("Schema Editor - " + this.parameter.name);
      $("#myModal").modal("show");
    },
    addEnum: function () {
      if (!this.parameter.schema) {
        Vue.set(this.parameter, "schema", {});
      }
      if (!this.parameter.schema.enum) {
        Vue.set(this.parameter.schema, "enum", []);
      }
      this.parameter.schema.enum.push("newValue");
    },
    removeEnum: function (index) {
      this.parameter.schema.enum.splice(index, 1);
    },
  },
  template: "#template-parameter",
  beforeMount: function () {
    if (this.parameter["$ref"]) {
      var ptr = this.parameter["$ref"].substr(1); // remove #
      try {
        var def = new JSONPointer(ptr).get(this.$root.container.openapi);
        for (var p in def) {
          this.parameter[p] = def[p];
        }
        delete this.parameter["$ref"];
      } catch (ex) {
        this.$root.showAlert("Could not find $ref " + this.parameter["$ref"]);
      }
    }
  },
});

Vue.component("api-mediatype", {
  props: ["content", "mediatype", "container"],
  computed: {
    mediaTypeName: {
      get: function () {
        return this.mediatype;
      },
      set: function (newVal) {
        this.$parent.renameMediaType(this.mediatype, newVal);
      },
    },
    schemaTooltip: {
      get: function () {
        if (!this.content.schema || !this.content.schema.$ref) {
          return "Edit inline schema";
        } else {
          var schemaName = this.content.schema.$ref.replace("#/components/schemas/", "");
          return "Edit shared schema (" + schemaName + ")";
        }
      },
    },
    selectedSchema: {
      get: function () {
        var ref = this.content.schema ? this.content.schema.$ref : "";
        if (ref) {
          return ref.replace("#/components/schemas/", "");
        }
        return undefined;
      },
      set: function (newVal) {
        Vue.set(this.content, "schema", {
          $ref: "#/components/schemas/" + newVal,
        });
        $("#" + this._uid).addClass("hidden");
        Buefy.Toast.open({
          message: "Schema " + newVal + " attached",
          type: "is-success",
        });
      },
    },
  },
  methods: {
    addMediaType: function () {
      this.$parent.addMediaType();
    },
    duplicateMediaType: function () {
      if (!this.container.content["change/me"]) {
        var newContent = clone(this.content);
        Vue.set(this.container.content, "change/me", newContent);
      }
    },
    editMediaType: function (mediatype) {
      var initial = deref(this.container.content[mediatype].schema, this.$parent.$parent.$parent.$parent.$parent.container.openapi);
      var editorOptions = {};
      var element = document.getElementById("schemaContainer");
      try {
        this.schemaEditor = new JSONEditor(element, editorOptions);
        this.schemaEditor.set(initial);
        this.schemaEditor.expandAll();
        schemaEditorClose = function () {
          this.schemaEditor.destroy();
          $("#myModal").modal("hide");
        }.bind(this);
        schemaEditorSave = function () {
          this.container.content[mediatype].schema = this.schemaEditor.get();
          schemaEditorClose();
        }.bind(this);
        $("#schemaModalTitle").text("Schema Editor ");
        $("#myModal").modal("show");
      } catch (ex) {
        console.log(ex);
        //this.$parent.$parent.showAlert('The editor could not be instantiated (circular schemas are not yet supported): '+ex.message);
      }
    },
    removeMediaType: function () {
      this.$parent.$parent.$parent.save();
      Vue.delete(this.container.content, this.mediatype);
      if (Object.keys(this.container.content).length == 0) {
        Vue.set(this.container.content, "application/json", { schema: {} });
      }
    },
    attachSchema: function (mediatype) {
      $("#" + this._uid).removeClass("hidden");
    },
  },
  data: function () {
    return {};
  },
});

Vue.component("api-response", {
  props: ["response", "status", "method"],
  beforeMount: function () {
    console.log(this.method.responses);
    if (this.method.responses[this.status].$ref) {
      var ptr = this.method.responses[this.status].$ref.substr(1); // remove #
      try {
        var def = new JSONPointer(ptr).get(this.$root.container.openapi);
        for (var p in def) {
          this.response[p] = def[p];
        }
        delete this.response.$ref;
      } catch (ex) {
        this.$root.showAlert("Could not find $ref " + this.method.responses[this.status].$ref);
      }
    }
  },
  computed: {
    statusCode: {
      get: function () {
        return this.status;
      },
      set: function (newVal) {
        this.$parent.renameResponse(this.status, newVal);
      },
    },
  },
  methods: {
    specLink: function (fragment) {
      return this.$parent.specLink(fragment);
    },
    addResponse: function () {
      this.$parent.addResponse();
    },
    removeResponse: function () {
      this.$parent.$parent.$parent.save();
      Vue.delete(this.method.responses, this.status);
      if (Object.keys(this.method.responses).length == 0) {
        Vue.set(this.method.responses, "default", {
          description: "Default response",
        });
      }
      this.$forceUpdate();
    },
    duplicateResponse: function () {
      console.log(JSON.stringify(this.method.responses));
      let newStatus = "default";
      if (this.method.responses.default) {
        newStatus = 200;
        while (this.method.responses[newStatus.toString()]) {
          newStatus++;
        }
        newStatus = newStatus.toString();
      }
      Vue.set(this.method.responses, newStatus, clone(this.method.responses[this.status]));
    },
    addMediaType: function () {
      if (!this.response.content) {
        Vue.set(this.response, "content", {});
      }
      if (!this.response.content["change/me"]) {
        Vue.set(this.response.content, "change/me", { schema: {} });
      }
      this.$forceUpdate();
    },
    renameMediaType: function (oldName, newName) {
      Vue.set(this.response.content, newName, this.response.content[oldName]);
      Vue.delete(this.response.content, oldName);
    },
  },
  data: function () {
    return {};
  },
});

Vue.component("api-mediatype-response", {
  props: ["content", "mediatype", "container"],
  computed: {
    mediaTypeName: {
      get: function () {
        return this.mediatype;
      },
      set: function (newVal) {
        this.$parent.renameMediaType(this.mediatype, newVal);
      },
    },
    schemaTooltip: {
      get: function () {
        if (!this.content.schema || !this.content.schema.$ref) {
          return "Edit inline schema";
        } else {
          var schemaName = this.content.schema.$ref.replace("#/components/schemas/", "");
          return "Edit shared schema (" + schemaName + ")";
        }
      },
    },
    selectedSchema: {
      get: function () {
        var ref = this.content.schema ? this.content.schema.$ref : "";
        if (ref) {
          return ref.replace("#/components/schemas/", "");
        }
        return undefined;
      },
      set: function (newVal) {
        Vue.set(this.content, "schema", {
          $ref: "#/components/schemas/" + newVal,
        });
        $("#" + this._uid).addClass("hidden");
        Buefy.Toast.open({
          message: "Schema " + newVal + " attached",
          type: "is-success",
        });
      },
    },
  },
  methods: {
    addMediaType: function () {
      this.$parent.addMediaType();
    },
    duplicateMediaType: function () {
      if (!this.container.content["change/me"]) {
        var newContent = clone(this.content);
        Vue.set(this.container.content, "change/me", newContent);
      }
    },
    editMediaType: function (mediatype) {
      var initial = deref(this.container.content[mediatype].schema, this.$parent.$parent.$parent.$parent.$parent.$parent.container.openapi);
      var editorOptions = {};
      var element = document.getElementById("schemaContainer");
      try {
        const options = {};
        const editor = new JSONEditor(element, editorOptions);
        editor.set(initial);
        schemaEditorClose = function () {
          editor.destroy();
          $("#myModal").modal("hide");
        }.bind(this);
        schemaEditorSave = function () {
          this.container.content[mediatype].schema = editor.get();
          // this.parameter.schema = editor.get();
          schemaEditorClose();
        }.bind(this);
        $("#schemaModalTitle").text("Schema Editor ");
        $("#myModal").modal("show");
      } catch (ex) {
        console.log(ex.message);
      }
    },
    removeMediaType: function () {
      this.$parent.$parent.$parent.$parent.$parent.save();
      Vue.delete(this.container.content, this.mediatype);
      if (Object.keys(this.container.content).length == 0) {
        Vue.set(this.container.content, "application/json", { schema: {} });
      }
    },
    attachSchema: function (mediatype) {
      $("#" + this._uid).removeClass("hidden");
    },
  },
  data: function () {
    return {};
  },
});
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.col-md-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 100;
}
</style>
