<template>
  <div class="col-lg-9 animated fadeInRight">
    <br />
    <ValidationObserver v-slot="{ invalid }">
      <form @submit.prevent="onSubmit" autocomplete="off">
        <div class="mail-box-header">
          <ValidationProvider
            name="Name"
            :rules="'required|alpha_num'" 
            v-slot="{ errors, classes }" 
          >
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Name *</label>

              <div class="col-sm-10"> 
                <input
                  type="text"
                  v-model="fileUpload.name"
                  class="form-control"
                  :class="classes"
                />
              </div>
            </div>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
          <div class="hr-line-dashed"></div>
          <ValidationProvider
            name="Group Name"
            :rules="{ required: true }"
            v-slot="{ errors, classes }"
          >
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Description *</label>
              <div class="col-sm-10">
                <input
                  type="text"
                  class="form-control"
                  v-model="fileUpload.description"
                  :class="classes"
                />
              </div>
            </div>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
          <div class="hr-line-dashed"></div>
          <ValidationProvider
            name="Type"
            :rules="{ required: true }"
            v-slot="{ errors, classes }"
          >
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Type *</label>
              <div class="col-sm-10">
                <select
                  class="form-control apiTypeClass"
                  v-model="fileUpload.type"
                  :class="classes"
                >
                  <option value="" selected>--------- Select ---------</option>
                  <option
                    v-for="(type, key) in apiType"
                    :value="type.id"
                    v-bind:key="key"
                  >
                    {{ type.text }}
                  </option>
                </select>
              </div>
            </div>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
          <div class="hr-line-dashed"></div>
          <ValidationProvider
            name="Method"
            :rules="{ required: true }"
            v-slot="{ errors, classes }"
          >
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Method *</label>
              <div class="col-sm-10">
                <select
                  class="form-control apiTypeClass"
                  v-model="fileUpload.method"
                  :class="classes"
                >
                  <option value="" selected>--------- Select ---------</option>
                  <option
                    v-for="(type, key) in apiMethod"
                    :value="type.id"
                    v-bind:key="key"
                  >
                    {{ type.text }}
                  </option>
                </select>
              </div>
            </div>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
          <div class="hr-line-dashed"></div>
          <ValidationProvider
            name="Service Provider"
            :rules="{ required: true }"
            v-slot="{ errors, classes }"
          >
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Service Provider *</label>
              <div class="col-sm-10">
                <select
                  class="form-control apiTypeClass"
                  v-model="fileUpload.serviceProvider"
                  :class="classes"
                >
                  <option value="" selected>--------- Select ---------</option>
                  <option
                    v-for="(type, key) in serviceProvider"
                    :value="type.id"
                    v-bind:key="key"
                  >
                    {{ type.text }}
                  </option>
                </select>
              </div>
            </div>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
          <div class="hr-line-dashed"></div>
          <ValidationProvider
            name="Endpoint"
            :rules="{ required: true }"
            v-slot="{ errors, classes }"
          >
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Endpoint *</label>
              <div class="col-sm-10">
                <input
                  type="text"
                  v-model="fileUpload.endpoint"
                  class="form-control"
                  :class="classes"
                />
              </div>
            </div>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
          <div class="hr-line-dashed"></div>

          <div class="form-group row" style="margin-bottom: 0rem">
            <div class="form-group col-md-2 switch">
              <label>Add Certification</label>
              <div
                class="onoffswitch"
                style="margin-top: -18%; margin-left: 121%"
              >
                <input
                  type="checkbox"
                  class="onoffswitch-checkbox"
                  id="addCertificationFlag"
                  value="true"
                />
                <label class="onoffswitch-label" for="addCertificationFlag">
                  <span class="onoffswitch-inner"></span>
                  <span class="onoffswitch-switch"></span>
                </label>
              </div>
            </div>
          </div>

          <div class="hr-line-dashed"></div>

          <ValidationProvider
            name="Api Product"
            :rules="{ required: true }"
            v-slot="{ errors, classes }"
          >
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">API Product *</label>
              <div class="col-sm-10">
                <select
                  data-placeholder="--------- Select ---------"
                  id="apiProduct"
                  class="form-control chosen-select"
                  multiple
                  tabindex="4"
                  :class="classes"
                ></select>
              </div>
            </div>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>

          <div class="hr-line-dashed"></div>
          <ValidationProvider
            name="API Group"
            :rules="{ required: true }"
            v-slot="{ errors, classes }"
          >
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">API Group *</label>
              <div class="col-sm-10">
                <select
                  class="form-control apiTypeClass"
                  v-model="fileUpload.groupId"
                  :class="classes"
                >
                  <option value="" selected>--------- Select ---------</option>
                  <option
                    v-for="(type, key) in apiGroup"
                    :value="type.id"
                    v-bind:key="key"
                  >
                    {{ type.text }}
                  </option>
                </select>
              </div>
            </div>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>

          <div class="hr-line-dashed"></div>

          <ValidationProvider
            name="File"
            :rules="{ required: true }"
            v-slot="{ errors, classes }"
          >
            <div class="custom-file">
              <input
                id="openApiFile"
                type="file"
                class="custom-file-input"
                :class="classes"
                data-vv-as="field"
              />
              <label for="openApiFile" class="custom-file-label"
                >Choose file...</label
              >
            </div>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
          <div class="hr-line-dashed"></div>

          <div class="form-group row">
            <div class="col-sm-4 col-sm-offset-2">
              <button
                class="btn btn-primary btn-sm"
                type="submit"
                :disabled="invalid"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import AxiosService from "../../plugin/AxiosService.js";
import Utils from "../../plugin/Utils";
import Constant from "../../plugin/Constant";
import AuditListner from "../../plugin/AuditListner";

export default {
  data() {
    return {
      fileUpload: {
        name: "",
        description: "",
        type: "",
        method: "",
        file: "",
        content: "",
        serviceProvider: "",
        endpoint: "",
        apiProduct: "",
        groupId: "",
        apiScope: "",
        apiScopeDescription: ""
      },
      apiTypeClass: "apiTypeClass",
    };
  },
  props: ["apiType", "apiMethod", "serviceProvider", "apiProductList", "apiGroup"],
  created: function () {},
  mounted: function () {
    const self = this;
    $(".chosen-select").chosen({ width: "100%" });
    $(".custom-file-input").on("change", function () {
      let fileName = $(this).val().split("\\").pop();
      $(this).next(".custom-file-label").addClass("selected").html(fileName);
      var fileReader = new FileReader();
      fileReader.onload = function () {
        var data = fileReader.result;
        self.fileUpload.content = atob(Utils.splitStr(data, ",", 2)[1]);
      };
      fileReader.readAsDataURL($(this).prop("files")[0]);
    });
  },
  methods: {
    onSubmit: function () {
      const self = this;
      this.fileUpload.apiProduct = $("#apiProduct").chosen().val().join();
      console.log(this.fileUpload);
      this.fileUpload.file = $("#openApiFile")[0].files[0];
      var isChecked = $(".onoffswitch-checkbox:checked").attr("value");
      this.fileUpload.addCertificate = isChecked == undefined ? false : true;
      AxiosService.post(
        Constant.url.API_DEFINITION_FILE_IMPORT_URI,
        this.fileUpload
      ).then((result) => {
        if (
          Utils.hasValue(result, Constant.data.RESPONSE_CODE) &&
          result.responseCode == Constant.data.SUCCESS
        ) {
          AuditListner.addAuditLog(
            AuditListner.initalizedData(
              Constant.data.API_DEFINITION_ACTIVITY,
              Constant.data.ADD_TITLE,
              AuditListner.apiDefinitionTemplate(self.fileUpload)
            )
          );
          self.$router.replace(Constant.url.SEPARATER + Constant.url.API_URL);
        }
      });
    },
  },
  watch: {
    apiProductList (newValue) {
        $.each(newValue, function (key, value) {
        $("#apiProduct").append(
          '<option value="' +
            value.id +
            '">' +
            value.description +
            "</option>"
        );
      });
      $("#apiProduct").trigger("chosen:updated");
    } 
  }
};
</script>
