<template>
  <div id="tab-tags" class="tab-pane">
    <div class="panel-body-api">
      <div class="row">
        <div class="col-md-3">
          <button
            v-on:click="addTag()"
            type="button"
            class="btn btn-w-m btn-primary"
          >
            <i class="fa fa-fw fa-plus"></i> Add Tag
          </button>
          <ul class="list-group clear-list m-t">
            <li
              v-for="(t, index) in openapi.tags"
              v-bind:key="index"
              class="list-group-item fist-item"
            >
              <a :href="'#tag' + index">{{ t.name }}</a>
            </li>
          </ul>
        </div>
        <div class="col-md-8">
          <div
            class="ibox-content"
            style="border: 1px solid; border-color: #f0f0f0; margin-top: 1%;"
            v-for="(t, index) in openapi.tags"
            v-bind:key="index"
          >
            <div class="form-group">
              <label
                >Name
                <i
                  class="fa fa-trash"
                  style=""
                  v-on:click="removeTag(index)"
                ></i
              ></label>
              <input
                type="text"
                class="form-control"
                v-model="t.name"
                placeholder="Name"
              />
            </div>
            <div class="form-group">
              <label>Description</label>
              <textarea
                class="form-control"
                v-model="t.description"
                placeholder="Description"
                rows="3"
              ></textarea>
            </div>
            <div class="form-group">
              <label>External Docs</label>
              <textarea
                class="form-control"
                v-model="t.externalDocs.description"
                placeholder="External Docs"
                rows="3"
              ></textarea>
            </div>
            <div class="form-group">
              <label>Docs URL</label>
              <input
                type="text"
                class="form-control"
                v-model="t.externalDocs.url"
                placeholder="Docs URL"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
export default {
  props: ["openapi"],
  methods: {
    addTag: function() {
      if (!this.openapi.tags) {
        Vue.set(this.openapi, "tags", []);
      }
      if (!this.openapi.tags.newTag) {
        var newTag = {};
        newTag.name = "newTag";
        newTag.externalDocs = {};
        this.openapi.tags.push(newTag);
      }
    },

    removeTag: function(index) {
      this.$parent.save();
      this.openapi.tags.splice(index, 1);
    },
  },
};
</script>
>
