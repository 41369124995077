<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-3">
        <div class="ibox">
          <div class="ibox-content mailbox-content">
            <div class="file-manager">
              <div class="space-25"></div>
              <h4 class="text-navy">Create API</h4>
              <span style="font-size: smaller">Create an API by importing from a file, URL or start from scratch <i class="fa fa-question-circle-o" aria-hidden="true"></i></span>
              <br /><br />
              <ul class="folder-list m-b-md" style="padding: 0">
                <li>
                  <a @click="setComponet('FILE')">
                    <div class="radio radio-primary">
                      <input id="checkbox1" type="radio" name="check1" :checked="isFile" />
                      <label for="checkbox1">
                        <i class="fa fa-file-code-o" style="padding-left: 10px"></i>
                        Import API from file
                      </label>
                    </div>
                  </a>
                </li>
                <li>
                  <a @click="setComponet('URL')">
                    <div class="radio radio-primary">
                      <input id="checkbox2" type="radio" name="check2" :checked="isURL" />
                      <label for="checkbox2">
                        <i class="fa fa-external-link" style="padding-left: 10px"></i>
                        Import API from URL
                      </label>
                    </div>
                  </a>
                </li>
                <li>
                  <a @click="setComponet('SCRATCH')">
                    <div class="radio radio-primary">
                      <input id="checkbox3" type="radio" name="check3" :checked="isScratch" />
                      <label for="checkbox3">
                        <i class="fa fa-pencil" style="padding-left: 10px"></i>
                        Create API from scratch
                      </label>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <ApiFileUpload v-show="isFile" :apiType="apiType" :apiMethod="apiMethod" :serviceProvider="serviceProvider" :apiProductList="apiProductList" :apiGroup="apiGroup"></ApiFileUpload>
      <ApiURL v-show="isURL" :apiType="apiType"></ApiURL>
      <ApiScratch v-show="isScratch" :isScratch="isScratch" :openapi="container.openapi"></ApiScratch>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import ApiFileUpload from "../components/common/ApiFileUpload";
import ApiScratch from "../components/common/ApiScratch";
import ApiURL from "../components/common/ApiURL";
import Constant from "../plugin/Constant";
import AxiosService from "../plugin/AxiosService";

export default {
  data() {
    return {
      isFile: true,
      isURL: false,
      isScratch: false,
      apiType: [],
      apiMethod: [],
      serviceProvider: [],
      apiProductList: {},
      apiGroup: {},
      container: {
        openapi: {
          openapi: "3.0.0",
          info: { title: "API", version: "1.0.0", contact: {}, license: {} },
          externalDocs: {},
          security: [],
          servers: [],
          paths: {},
          components: { links: {}, callbacks: {}, schemas: {} },
        },
      },
      specVersion: "master",
    };
  },
  components: {
    ApiFileUpload,
    ApiScratch,
    ApiURL,
  },
  mounted: function () {
    this.getApiDefinitionType();
  },
  methods: {
    setComponet: function (key) {
      if (key == "FILE") {
        this.isFile = true;
        this.isURL = false;
        this.isScratch = false;
      } else if (key == "URL") {
        this.isFile = false;
        this.isURL = true;
        this.isScratch = false;
      } else if (key == "SCRATCH") {
        this.isFile = false;
        this.isURL = false;
        this.isScratch = true;
      } else {
        console.log("Invalid Option");
      }
    },
    getApiDefinitionType: function () {
      AxiosService.get(Constant.url.API_DEFINITION_TYPE_KEY_VALUE).then((result) => {
        this.apiType = result;
      });
      AxiosService.get(Constant.url.API_DEFINITION_METHOD_KEY_VALUE).then((result) => {
        this.apiMethod = result;
      });
       AxiosService.get(Constant.url.API_DEFINITION_SERVICE_PROVIDER_KEY_VALUE).then(
        (result) => {
          this.serviceProvider = result;
        }
      );
       AxiosService.get(Constant.url.PRODUCT_KEYVALUE_URI).then(result => {
         this.apiProductList = result;
      });
      AxiosService.get(Constant.url.API_GROUP_KEYVALUE_URI).then(result => {
         this.apiGroup = result;
      });
    },
    specLink: function (fragment) {
      return "https://github.com/OAI/OpenAPI-Specification/blob/" + this.specVersion + "/versions/3.0.0.md" + (fragment ? fragment : "");
    },
    save: function () {
      if (window.localStorage) {
        window.localStorage.setItem("openapi3", JSON.stringify(this.container.openapi));
      }
      if (window.intelligentBackend) {
        var data = new FormData();
        data.append("source", JSON.stringify(this.container.openapi));
        $.ajax({
          url: "/store",
          type: "POST",
          contentType: false,
          processData: false,
          data: data,
          success: function (result) {},
        });
      }
    },
    postProcessDefinition: function (openapi) {
      var def = clone(openapi);
      for (var p in def.paths) {
        this.postProcessPathItem(def.paths[p]);
      }
      for (var t in def.tags) {
        var tag = def.tags[t];
        if (tag.externalDocs && !tag.externalDocs.url) {
          Vue.delete(tag, "externalDocs");
        }
      }
      if (def.externalDocs && !def.externalDocs.url) {
        Vue.delete(def, "externalDocs");
      }
      if (def.info && def.info.license && !def.info.license.name) {
        Vue.delete(def.info, "license");
      }
      return def;
    },
    postProcessPathItem: function (pi) {
      for (var o in pi) {
        var op = pi[o];
        if (op.externalDocs && !op.externalDocs.url) {
          Vue.delete(op, "externalDocs");
        }
        if (op.tags) {
          if (op.tags.length === 0) {
            Vue.delete(op, "tags");
          } else {
            Vue.set(op, "tags", op.tags.filter(onlyUnique));
          }
        }
        if (op.callbacks) {
          for (var c in op.callbacks) {
            var callback = op.callbacks[c];
            for (var e in callback) {
              var exp = callback[e];
              this.postProcessPathItem(exp);
            }
          }
        }
      }
      return pi;
    },
  },
};
</script>
<style scoped>
.folder-list li i {
  margin-right: 12px;
  color: #3d4d5d;
}
.col-lg-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 19%;
}
</style>
