<template>
  <div id="tab-yaml" class="tab-pane">
    <div class="block-container">
      
    </div>
    <div class="panel-body">
      <div class="row" style="margin-left: 0%;">
        <a class="export-option" id="copy-yaml" data-clipboard-target="#pretty-yaml"> <i class="fa fa-fw fa-copy"></i>Copy </a>
        <a class="export-option" style="margin-left: 1%;" id="download-yaml"> <i class="fa fa-fw fa-download"></i>Download </a> 
        <br>
      </div>
       <div id="yaml-output"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["openapi"],
  methods: {},
  created: function() {
  }
};
</script>
<style scoped>
.export-option {
    color: #343a40 !important;
}
</style>